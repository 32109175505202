import React, { useEffect, useState } from "react";
import ViewDocumentIcon from "../../components/ViewDocumentIcon";
import DeleteDocumentIcon from "../../components/DeleteDocumentIcon";
import AppLayout from "../../components/layouts/AppLayout";
import {
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import {
  CaretDownOutlined,
  DeleteOutlined,
  EyeOutlined,
  FileExcelOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { format, validate } from "rut.js";
import Button from "../../components/Button";
import { useMediaPredicate } from "react-media-hook";
import {
  getMyProfileRequest,
  updateMyProfile,
  updateProfilePicture,
} from "../../requests/profile";
import Notifications from "../../components/Notifications";
import moment from "moment/dist/moment";
import {
  deteleDriverLicense,
  getDriverLicense,
  getProfilePic,
  updateDriverLicense,
  updateDriverIdentityCard,
  getDriverIdentityCard,
  updateDriverProfile,
} from "../../requests/users";
import { getAuthProfile, getPermissions } from "../../store/auth/thunks";
import { useDispatch } from "react-redux";
import esES from "antd/lib/locale/es_ES";
import "moment/locale/es";

import "../DriverPerfil/index.css";

const { Text } = Typography;

var stopClickEvent = 1;

export default function DriverPerfil() {
  const [form] = Form.useForm();
  const [validationMessage, setValidationMessage] = useState();
  const [documentType, setDocumentType] = useState("Carnet de identidad");
  const [profilePicture, setProfilePicture] = useState();
  const [profileInfo, setProfileInfo] = useState();
  const [reloadProfile, setReloadProfile] = useState(false);
  const biggerThan900 = useMediaPredicate("(min-width: 1175px)");
  const dispatch = useDispatch();

  const handleDocumentChange = (value) => {
    setDocumentType(value);
  };

  const fileTypes = [
    "image/jpeg",
    "image/png",
    "'image/jpg'",
    "application/pdf",
  ];

  useEffect(() => {
    const getProfileData = async () => {
      try {
        const { data } = await getMyProfileRequest();
        console.log("data from getMyProfileRequest", data);

        setProfileInfo(data);
        form.setFieldsValue({
          documentType: data?.identifierType || "Carnet de identidad",
          email: data?.email,
          rut: data?.rut,
          driverLastName: data?.lastName,
          phoneNumber: data?.phoneNumber,
          driverName: data?.firstName,
          LicenseEndDate: data?.licenseExpirationDate
            ? moment(data.licenseExpirationDate)
            : null,
        });

        // Validate LicenseEndDate after setting the form fields
        const initialDate = data?.licenseExpirationDate
          ? moment(data.licenseExpirationDate)
          : null;

        if (initialDate && initialDate.isBefore(moment(), "day")) {
          setValidationMessage(
            "Su licencia se encuentra  vencida, por favor renovarla."
          );
        } else {
          setValidationMessage("");
        }
      } catch (error) {
        Notifications.error(
          "Error al cargar los datos.",
          "Ha ocurrido un error al procesar tu solicitud, si el incidente continua por favor comunicarse con el equipo de soporte Gama."
        );
      }
    };

    const getProfilePicture = async () => {
      try {
        const { data } = await getProfilePic();
        setProfilePicture(data);
      } catch (error) {
        console.error(error);
      }
    };

    getProfilePicture();
    getProfileData();
  }, [reloadProfile, form]);

  const disablePastDates = (current: moment.Moment) => {
    return current && current < moment().startOf("day");
  };

  const updateProfileInfo = async (values) => {
    console.log(values);
    try {
      const { data } = await updateMyProfile({
        firstName: values?.driverName,
        lastName: values?.driverLastName,
        phonePrefix: "56",
        phoneNumber: values?.phoneNumber?.toString(),
        licenseExpirationDate: values?.LicenseEndDate,
        email: values?.email,
      });

      Notifications.success(
        "Mis datos",
        `Se han actualizado los datos con éxito.`
      );
    } catch (error) {
      console.error(error);
      Notifications.error(
        "Error al actualizar",
        "Ha ocurrido un error al procesar tu solicitud, si el incidente continua por favor comunicarse con el equipo de soporte Gama."
      );
    }
  };

  const uploadDriverLicense = async (file) => {
    setReloadProfile(false);
    console.log(file.file.originFileObj);

    try {
      if (file.fileList[0].originFileObj > 1) {
        file.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "No se puede subir mas de una imagen."
        );
      } else if (!fileTypes.includes(file?.fileList[0]?.originFileObj?.type)) {
        file.fileList.pop();
        Modal.error({
          content: (
            <>
              <div>Archivo no valido</div>
              <div>Archivos permitidos: </div>
              <span>jpeg, pdf, png.</span>
            </>
          ),
        });
      } else if (file?.fileList[0]?.originFileObj?.size >= 7000000) {
        file.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "El peso del archivo supera el máximo de 7MB."
        );
      } else {
        const { data } = await updateDriverLicense(
          file.fileList?.slice(-1).map((file) => file.originFileObj)
        );
        setReloadProfile(true);
        Notifications.success(
          "Licencia actualizada",
          "Se ha actualizado la licencia satisfactoriamente."
        );
      }
    } catch (error) {
      console.log(error, "error from profile");
      Notifications.error(
        "Error al cargar",
        "Ocurrio un error al cargar la imagen."
      );
    }
  };

  const uploadDriverIdentityCardA = async (file) => {
    setReloadProfile(false);
    try {
      if (file.fileList[0].originFileObj > 1) {
        file.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "No se puede subir mas de una imagen."
        );
      } else if (!fileTypes.includes(file?.fileList[0]?.originFileObj?.type)) {
        file.fileList.pop();
        Modal.error({
          content: (
            <>
              <div>Archivo no valido</div>
              <div>Archivos permitidos: </div>
              <span>jpeg, pdf, png.</span>
            </>
          ),
        });
      } else if (file?.fileList[0]?.originFileObj?.size >= 7000000) {
        file.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "El peso del archivo supera el máximo de 7MB."
        );
      } else {
        await updateDriverIdentityCard(
          file.fileList?.slice(-1).map((file) => file.originFileObj),
          "DOCID_A"
        );
        setReloadProfile(true);
        Notifications.success(
          "Carnet de identidad actualizado",
          "Se ha actualizado la parte delantera de su carnet de identidad satisfactoriamente."
        );
      }
    } catch (error) {
      console.log(error, "error from profile");
      Notifications.error(
        "Error al cargar",
        "Ocurrio un error al cargar el archivo."
      );
    }
  };

  const uploadDriverIdentityCardB = async (file) => {
    setReloadProfile(false);
    try {
      if (file.fileList[0].originFileObj > 1) {
        file.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "No se puede subir mas de una imagen."
        );
      } else if (!fileTypes.includes(file?.fileList[0]?.originFileObj?.type)) {
        file.fileList.pop();
        Modal.error({
          content: (
            <>
              <div>Archivo no valido</div>
              <div>Archivos permitidos: </div>
              <span>jpeg, pdf, png.</span>
            </>
          ),
        });
      } else if (file?.fileList[0]?.originFileObj?.size >= 7000000) {
        file.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "El peso del archivo supera el máximo de 7MB."
        );
      } else {
        await updateDriverIdentityCard(
          file.fileList?.slice(-1).map((file) => file.originFileObj),
          "DOCID_B"
        );
        setReloadProfile(true);
        Notifications.success(
          "Carnet de identidad actualizado",
          "Se ha actualizado la parte posterior de su carnet de identidad satisfactoriamente."
        );
      }
    } catch (error) {
      console.log(error, "error from profile");
      Notifications.error(
        "Error al cargar",
        "Ocurrio un error al cargar el archivo."
      );
    }
  };

  const handlePictureChange = async (info) => {
    console.log(info, "info from list");
    setReloadProfile(false);
    try {
      if (info.fileList[0].originFileObj > 1) {
        setReloadProfile(true);
        info.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "No se puede subir mas de una imagen."
        );
      } else if (!fileTypes.includes(info?.fileList[0]?.originFileObj?.type)) {
        setReloadProfile(true);
        info.fileList.pop();
        Modal.error({
          content: (
            <>
              <div>Archivo no valido</div>
              <div>Archivos permitidos: </div>
              <span>jpeg, png.</span>
            </>
          ),
        });
      } else if (info?.fileList[0]?.originFileObj?.size >= 10000000) {
        setReloadProfile(true);
        info.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "El peso del archivo supera el máximo de 20MB."
        );
      } else {
        if (stopClickEvent === 3) {
          await updateProfilePicture(info?.file?.originFileObj);
          Notifications.success(
            "Cambio de imagen",
            "Se ha actualizado su imagen de perfil satisfactoriamente."
          );
          stopClickEvent = 1;
          dispatch(getAuthProfile());
          dispatch(getPermissions());
        }

        setReloadProfile(true);
      }
    } catch (error) {
      setReloadProfile(true);
      console.log(error, "error from profile");
      Notifications.error(
        "Error al cargar",
        "Ocurrio un error al cargar la imagen."
      );
    } finally {
      setReloadProfile(false);
    }
  };

  const handleViewLicense = async () => {
    try {
      const { data } = await getDriverLicense();
      const byteCharacters = atob(data?.buffer);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const file = new Blob([byteArray], {
        type: data?.contentType,
      });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.error(error, "error");
    }
  };

  const handleViewIdentityCard = async (documentType) => {
    try {
      const { data } = await getDriverIdentityCard(documentType);
      const byteCharacters = atob(data?.buffer);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const file = new Blob([byteArray], {
        type: data?.contentType,
      });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.error(error, "error");
    }
  };

  const handleDeleteLicense = async () => {
    setReloadProfile(false);
    const deleteObj = {
      driverLicenseImageFilename: null,
    };
    try {
      const { data } = await deteleDriverLicense(deleteObj);
      setReloadProfile(true);
      Notifications.success(
        "Licencia eliminada",
        "Se ha eliminado la licensia satisfactoriamente."
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteIdentityCardA = async () => {
    setReloadProfile(false);
    const deleteObj = {
      identityDocumentImageFilenameA: null,
    };
    try {
      await updateDriverProfile(deleteObj);
      setReloadProfile(true);
      Notifications.success(
        "Carnet de Identidad",
        "Se ha eliminado la parte frontal de su carnet de identidad satisfactoriamente."
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteIdentityCardB = async () => {
    setReloadProfile(false);
    const deleteObj = {
      identityDocumentImageFilenameB: null,
    };
    try {
      await updateDriverProfile(deleteObj);
      setReloadProfile(true);
      Notifications.success(
        "Carnet de Identidad",
        "Se ha eliminado la parte posterior de su carnet de identidad satisfactoriamente."
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateChange = (date) => {
    if (date && date.isBefore(moment(), "day")) {
      setValidationMessage(
        "Su licencia se encuentra  vencida, por favor renovarla."
      );
    } else {
      setValidationMessage("");
    }
  };

  const customDividir = (text) => (
    <Divider variant="dashed" dashed>
      <strong>{text}</strong>
    </Divider>
  );

  const fileIcon = () => (
    <FileExcelOutlined
      style={{
        fontSize: "20px",
        color: "#8C8C8C",
      }}
    />
  );

  const uploadControl = (lable, handleUpload) => (
    <Upload
      customRequest={() => {}}
      maxCount={1}
      onChange={(e) => handleUpload(e)}
      onPreview={{}}
      showUploadList={false}
    >
      <Form.Item
        className="uploadButtonDesktop"
        label={lable}
        style={{ width: "100%" }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            border: "1px dashed #ff5f00",
            cursor: "pointer",
            width: "100%",
          }}
        >
          <div>
            <UploadOutlined style={{ color: "#FF5F00" }} />{" "}
            <span style={{ color: "#FF5F00" }}>Subir archivo</span>
          </div>
        </div>
        <div
          style={{
            color: "var(--Neutral-6, #bfbfbf)",
            fontFamily: "Roboto",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px",
            marginTop: "8px",
          }}
        >
          Archivos JPG, PNG, PDF - Tamaño máximo 7MB.
        </div>
      </Form.Item>
    </Upload>
  );

  return (
    <AppLayout title="Mis datos">
      <Row
        align={biggerThan900 ? "start" : "center"}
        style={{ marginTop: "20px", marginBottom: "10px", marginLeft: "24px" }}
      >
        <img
          style={{
            borderRadius: "50%",
            cursor: "pointer",
            height: "80px",
            width: "80px",
          }}
          src={profilePicture}
          alt="profile"
        />
      </Row>
      <Row
        style={{ marginBottom: "15px", marginLeft: "24px" }}
        align={biggerThan900 ? "start" : "center"}
      >
        <Upload
          showUploadList={false}
          accept=".png,.jpg,.jpeg,.webp"
          onChange={(info) => {
            if (stopClickEvent === 3) {
              handlePictureChange(info);
              stopClickEvent = 1;
            } else {
              stopClickEvent = stopClickEvent + 1;
            }
          }}
        >
          <span
            style={{
              textDecoration: "underline",
              color: "#FF5F00",
              cursor: "pointer",
            }}
          >
            Actualizar foto
          </span>
        </Upload>
      </Row>

      {biggerThan900 ? (
        <Form
          name="driverData"
          onFinish={updateProfileInfo}
          form={form}
          labelCol={{
            span: 24,
          }}
          layout="vertical"
          style={{ marginLeft: "25px" }}
        >
          <Row>
            <Form.Item
              name="documentType"
              rules={[
                { required: true, message: "Ingrese el tipo de documento." },
              ]}
              label="Tipo Documento"
              style={{
                fontWeight: "bold",
                marginBottom: "20px",
                width: biggerThan900 ? "40%" : "100%",
                marginRight: biggerThan900 ? "50px" : "",
              }}
            >
              <Select
                onChange={(value) => handleDocumentChange(value)}
                placeholder="Seleccionar"
                suffixIcon={<CaretDownOutlined />}
                id="vehicle-selector"
                disabled={true}
              >
                <Select.Option value="Carnet de identidad">
                  Carnet de identidad
                </Select.Option>
                <Select.Option value="Pasaporte">Pasaporte</Select.Option>
              </Select>
            </Form.Item>
            <ConfigProvider locale={esES}>
              <Form.Item
                name="LicenseEndDate"
                label="Fecha de expiración de la licencia"
                validateStatus={validationMessage ? "error" : ""}
                help={validationMessage || null}
                style={{
                  width: biggerThan900 ? "50%" : "100%",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  style={{ width: "100%" }}
                  placeholder={["Fecha expiración"]}
                  onChange={handleDateChange}
                />
              </Form.Item>
            </ConfigProvider>
          </Row>
          <Row>
            <Form.Item
              rules={[
                { required: true, message: "Debe ingresar un nombre." },
                {
                  max: 50,
                  message: "Debe tener máximo 50 caracteres",
                },
                {
                  pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                  message:
                    "La razón social no puede incluir caracteres especiales",
                },
                {
                  min: 3,
                  message: "Debe tener al menos 3 caracteres",
                },
              ]}
              name="driverName"
              label="Nombres"
              style={{
                width: biggerThan900 ? "40%" : "100% ",
                fontWeight: "bold",
                marginBottom: "20px",
                marginRight: biggerThan900 ? "50px" : "",
              }}
            >
              <Input />
            </Form.Item>
            <Input.Group style={{ width: "50%" }} compact>
              <Form.Item
                style={{ marginRight: "4px" }}
                label={<Text strong>Prefijo</Text>}
                name="phonePrefix"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select disabled defaultValue="56" style={{ width: 80 }}>
                  <Select.Option value={"56"}>+56</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={<Text strong>Teléfono</Text>}
                name="phoneNumber"
                style={{
                  width: "65%",
                }}
                rules={[
                  {
                    required: true,
                    message: "Ingrese el número de telefono",
                  },
                  {
                    pattern: /^[+]*\d{9}$/,
                    message:
                      "Ingrese un número válido, sin letras y de 9 dígitos.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Input.Group>
          </Row>
          <Row style={{ height: "100px" }}>
            <Form.Item
              rules={[
                { required: true, message: "Debe ingresar un apellido." },
                {
                  max: 50,
                  message: "Debe tener máximo 50 caracteres",
                },
                {
                  pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                  message:
                    "La razón social no puede incluir caracteres especiales",
                },
                {
                  min: 3,
                  message: "Debe tener al menos 3 caracteres",
                },
              ]}
              name="driverLastName"
              label="Apellidos"
              style={{
                width: biggerThan900 ? "40%" : "100%",
                fontWeight: "bold",
                marginBottom: "20px",
                marginRight: biggerThan900 ? "50px" : "",
              }}
            >
              <Input />
            </Form.Item>
            <Col style={{ width: "50%" }}>
              {customDividir("Licencia")}
              <div style={{ width: "100%" }}>
                {uploadControl("Adjuntar licencia", uploadDriverLicense)}
              </div>
              {profileInfo?.driverLicenseImageFilename !== null && (
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#8C8C8C",
                      fontSize: 12,
                      padding: 5,
                      alignItems: "center",
                      border: "solid 1px #FF5F00",
                      display: "flex",
                      gap: "5px",
                      borderRadius: 10,
                    }}
                  >
                    {fileIcon()}
                    {profileInfo?.driverLicenseImageFilename}
                  </div>
                  <ViewDocumentIcon handleView={() => handleViewLicense} />
                  <DeleteDocumentIcon handleDelete={handleDeleteLicense} />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            {documentType === "Carnet de identidad" ? (
              <Form.Item
                name="rut"
                rules={[
                  { required: true, message: "Ingrese el rut" },
                  {
                    validator: (_, value) => {
                      if (validate(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject("El rut ingresado es inválido");
                    },
                  },
                ]}
                normalize={(value) => format(value)}
                label="Carnet de identidad"
                style={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  width: biggerThan900 ? "40%" : "100%",
                  marginRight: "45px",
                }}
              >
                <Input disabled={true} />
              </Form.Item>
            ) : (
              <Form.Item
                name="passport"
                rules={[{ required: false, message: "Ingrese el pasaporte" }]}
                label="Pasaporte"
                style={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  width: biggerThan900 ? "40%" : "100%",
                }}
              >
                <Input disabled={true} />
              </Form.Item>
            )}
          </Row>
          <Row>
            <Form.Item
              style={{
                width: "40%",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
              name="email"
              label="Correo electrónico"
              rules={[
                {
                  type: "email",
                  message: "Por favor ingrese un correo válido",
                },
                {
                  required: true,
                  message: "Por favor ingrese el correo",
                },
                {
                  max: 50,
                  message:
                    "No puede ingresar más de 50 caracteres en un correo",
                },
                {
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Row>
          <Row
            justify="end"
            style={{ width: "100%", marginBottom: 40, paddingRight: 30 }}
          >
            <Col span={13}>
              {customDividir("Carnet de identidad")}
              {uploadControl(
                "Adjuntar la parte frontal de su carnet de identidad.",
                uploadDriverIdentityCardA
              )}
              {profileInfo?.identityDocumentImageFilenameA !== null && (
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#8C8C8C",
                      fontSize: 12,
                      padding: 5,
                      alignItems: "center",
                      border: "solid 1px #FF5F00",
                      display: "flex",
                      gap: "5px",
                      borderRadius: 10,
                    }}
                  >
                    {fileIcon()}
                    {profileInfo?.identityDocumentImageFilenameA}
                  </div>
                  <ViewDocumentIcon
                    handleView={() =>
                      handleViewIdentityCard("IDENTITY_DOCUMENT_A")
                    }
                  />
                  <DeleteDocumentIcon
                    handleDelete={handleDeleteIdentityCardA}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row
            justify="end"
            style={{ width: "100%", marginBottom: 40, paddingRight: 30 }}
          >
            <Col span={13}>
              {uploadControl(
                "Adjuntar la parte posterior de su carnet de identidad.",
                uploadDriverIdentityCardB
              )}

              {profileInfo?.identityDocumentImageFilenameB !== null && (
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#8C8C8C",
                      fontSize: 12,
                      padding: 5,
                      alignItems: "center",
                      border: "solid 1px #FF5F00",
                      display: "flex",
                      gap: "5px",
                      borderRadius: 10,
                    }}
                  >
                    {fileIcon()}
                    {profileInfo?.identityDocumentImageFilenameB}
                  </div>
                  <ViewDocumentIcon
                    handleView={() =>
                      handleViewIdentityCard("IDENTITY_DOCUMENT_B")
                    }
                  />
                  <DeleteDocumentIcon
                    handleDelete={handleDeleteIdentityCardB}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row style={{ width: "100%" }} justify={"end"}>
            <Button
              style={{ width: "20%", marginRight: "10px" }}
              className="gama-outline"
              type="primary"
              onClick={() => {}}
            >
              Salir
            </Button>

            <Button
              style={{ width: "30%", marginRight: "3%" }}
              type="primary"
              onClick={() => form.submit()}
            >
              Actualizar datos
            </Button>
          </Row>
        </Form>
      ) : (
        <Form
          name="driverData"
          onFinish={updateProfileInfo}
          form={form}
          labelCol={{
            span: 24,
          }}
          layout="vertical"
          style={{ marginLeft: "25px" }}
        >
          <Form.Item
            name="documentType"
            rules={[
              { required: true, message: "Ingrese el tipo de documento." },
            ]}
            label="Tipo Documento"
            style={{
              fontWeight: "bold",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <Select
              onChange={(value) => handleDocumentChange(value)}
              placeholder="Seleccionar"
              suffixIcon={<CaretDownOutlined />}
              id="vehicle-selector"
              disabled={true}
            >
              <Select.Option value="Carnet de identidad">
                Carnet de identidad
              </Select.Option>
              <Select.Option value="Pasaporte">Pasaporte</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Debe ingresar un nombre." },
              {
                max: 50,
                message: "Debe tener máximo 50 caracteres",
              },
              {
                min: 3,
                message: "Debe tener al menos 3 caracteres",
              },
              {
                pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                message:
                  "La razón social no puede incluir caracteres especiales",
              },
            ]}
            name="driverName"
            label="Nombres"
            style={{
              width: "100% ",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Debe ingresar un apellido." },
              {
                min: 3,
                message: "Debe tener al menos 3 caracteres",
              },
              {
                max: 50,
                message: "Debe tener máximo 50 caracteres",
              },
              {
                pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                message:
                  "La razón social no puede incluir caracteres especiales",
              },
            ]}
            name="driverLastName"
            label="Apellidos"
            style={{
              width: biggerThan900 ? "40%" : "100%",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            <Input />
          </Form.Item>
          {documentType === "Carnet de identidad" ? (
            <Form.Item
              name="rut"
              rules={[
                { required: true, message: "Ingrese el rut" },
                {
                  validator: (_, value) => {
                    if (validate(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("El rut ingresado es inválido");
                  },
                },
              ]}
              normalize={(value) => format(value)}
              label="Carnet de identidad"
              style={{ fontWeight: "bold", marginBottom: "20px" }}
            >
              <Input disabled={true} />
            </Form.Item>
          ) : (
            <Form.Item
              name="passport"
              rules={[{ required: true, message: "Ingrese el pasaporte" }]}
              label="Pasaporte"
              style={{ fontWeight: "bold", marginBottom: "20px" }}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            style={{
              width: "100%",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
            name="email"
            label="Correo electrónico"
            rules={[
              {
                type: "email",
                message: "Por favor ingrese un correo válido",
              },
              {
                required: true,
                message: "Por favor ingrese el correo",
              },
              {
                max: 100,
                message: "No puede ingresar más de 100 caracteres en un correo",
              },
              {
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <ConfigProvider locale={esES}>
            <Form.Item
              name="LicenseEndDate"
              label="Fecha de expiración de la licencia"
              validateStatus={validationMessage ? "error" : ""}
              help={validationMessage || null}
              style={{
                width: "100%",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              <DatePicker
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
                placeholder={["Fecha inicio"]}
                onChange={handleDateChange}
              />
            </Form.Item>
          </ConfigProvider>

          <Input.Group compact>
            <Form.Item
              style={{ marginRight: "4px" }}
              label={<Text strong>Prefijo</Text>}
              name="phonePrefix"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select disabled defaultValue="56" style={{ width: 80 }}>
                <Select.Option value={"56"}>+56</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={<Text strong>Teléfono</Text>}
              name="phoneNumber"
              style={{
                width: "70%",
              }}
              rules={[
                {
                  required: true,
                  message: "Ingrese el número de telefono",
                },
                {
                  pattern: /^[+]*\d{9}$/,
                  message:
                    "Ingrese un número válido, sin letras y de 9 dígitos.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Input.Group>

          {/* **** Licencia *** */}
          {customDividir("Licencia")}
          <Row style={{ width: "100%" }}>
            <Col className="aaa" style={{ width: "100%" }} span={24}>
              <Upload
                className="uploadButton"
                customRequest={() => {}}
                maxCount={1}
                onChange={(e) => uploadDriverLicense(e)}
                showUploadList={false}
              >
                <Form.Item label="Adjuntar licencia">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      border: "1px dashed #ff5f00",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <div>
                      <UploadOutlined style={{ color: "#FF5F00" }} />{" "}
                      <span
                        style={{
                          color: "#FF5F00",
                        }}
                      >
                        Subir archivo
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "var(--Neutral-6, #bfbfbf)",
                      fontFamily: "Roboto",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "16px",
                      marginTop: "8px",
                    }}
                  >
                    Archivos JPG, PNG, PDF - Tamaño máximo 7MB.
                  </div>
                </Form.Item>
              </Upload>
            </Col>
          </Row>
          {profileInfo?.driverLicenseImageFilename !== null ? (
            <Row justify="start" style={{ width: "100%" }}>
              <Row
                justify="start"
                style={{
                  border: "1px solid #FF5F00",
                  padding: "6px 5px",
                  borderRadius: "6px",
                  width: "80%",
                  height: "45px",
                }}
              >
                <Col>
                  <FileExcelOutlined
                    style={{
                      fontSize: "20px",
                      marginRight: "8px",
                      marginLeft: "10px",
                      color: "#8C8C8C",
                    }}
                  />
                </Col>
                <Col style={{ color: "#8C8C8C", fontSize: 12 }}>
                  {profileInfo?.driverLicenseImageFilename}
                </Col>
              </Row>
              <Row align={"middle"}>
                <Col>
                  <EyeOutlined
                    onClick={handleViewLicense}
                    style={{
                      fontSize: "20px",
                      color: "#FF5F00",
                      marginLeft: "12px",
                      marginTop: "12px",
                      cursor: "pointer",
                    }}
                  />
                </Col>
                <Col>
                  <DeleteOutlined
                    onClick={handleDeleteLicense}
                    style={{
                      fontSize: "20px",
                      color: "#FF5F00",
                      marginLeft: "15px",
                      marginTop: "12px",
                      cursor: "pointer",
                    }}
                  />
                </Col>
              </Row>
            </Row>
          ) : null}
          {/* **** Licencia *** */}

          {/* **** Carnet de identidad *** */}
          <Row gutter={[10, 30]} style={{ marginTop: 30 }}>
            <Col style={{ width: "100%" }}>
              {customDividir("Carnet de identidad")}
            </Col>
            <Col style={{ width: "100%" }}>
              <div>
                {uploadControl(
                  "Adjuntar la parte frontal de su carnet de identidad.",
                  uploadDriverIdentityCardA
                )}
              </div>

              {profileInfo?.identityDocumentImageFilenameA !== null && (
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#8C8C8C",
                      fontSize: 12,
                      padding: 5,
                      alignItems: "center",
                      border: "solid 1px #FF5F00",
                      display: "flex",
                      gap: "5px",
                      borderRadius: 10,
                      flex: 1,
                    }}
                  >
                    {fileIcon()}
                    {profileInfo?.identityDocumentImageFilenameA}
                  </div>
                  <ViewDocumentIcon
                    handleView={() =>
                      handleViewIdentityCard("IDENTITY_DOCUMENT_A")
                    }
                  />
                  <DeleteDocumentIcon
                    handleDelete={handleDeleteIdentityCardA}
                  />
                </div>
              )}
            </Col>
            <Col style={{ width: "100%" }}>
              <div>
                {uploadControl(
                  "Adjuntar la parte posterior de su carnet de identidad.",
                  uploadDriverIdentityCardB
                )}
              </div>

              {profileInfo?.identityDocumentImageFilenameB !== null && (
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#8C8C8C",
                      fontSize: 12,
                      padding: 5,
                      alignItems: "center",
                      border: "solid 1px #FF5F00",
                      display: "flex",
                      gap: "5px",
                      borderRadius: 10,
                      flex: 1,
                    }}
                  >
                    {fileIcon()}
                    {profileInfo?.identityDocumentImageFilenameB}
                  </div>
                  <ViewDocumentIcon
                    handleView={() =>
                      handleViewIdentityCard("IDENTITY_DOCUMENT_B")
                    }
                  />
                  <DeleteDocumentIcon
                    handleDelete={handleDeleteIdentityCardB}
                  />
                </div>
              )}
            </Col>
          </Row>
          {/* **** Carnet de identidad *** */}

          <Row
            align="middle"
            justify="center"
            style={{ marginTop: "30px", marginBottom: "20px" }}
          >
            <Col span={24}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => form.submit()}
              >
                Actualizar datos
              </Button>
            </Col>
          </Row>
          <Row align="middle" justify="center" style={{ marginBottom: "30px" }}>
            <Button
              style={{ width: "100%" }}
              className="gama-outline"
              type="primary"
              onClick={() => {}}
            >
              Salir
            </Button>
          </Row>
        </Form>
      )}
    </AppLayout>
  );
}
