import { DeleteOutlined } from "@ant-design/icons";

export default function DeleteDocumentIcon({ handleDelete }) {
  return (
    <DeleteOutlined
      onClick={handleDelete}
      style={{
        fontSize: "20px",
        color: "#FF5F00",
        cursor: "pointer",
      }}
    />
  );
}
