import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Row, Tag, Typography, Spin } from "antd";
import ProfileUserCard from "../ProfileUserCard";
import { CloseOutlined } from "@ant-design/icons";
import CustomButton from "../../../components/Button/index";
import FileLink from "../../../components/FileLink";
import { useMediaPredicate } from "react-media-hook";
import {
  getProfilePicture,
  getProfileIdentityCardImage,
} from "../../../requests/profile";

const { Text } = Typography;
const IDENTITY_DOCUMENT_LABELS = {
  IDENTITY_DOCUMENT_A: "Carnet de identidad (frontal)",
  IDENTITY_DOCUMENT_B: "Carnet de identidad (posterior)",
  DRIVER_LICENSE: "Licencia de conducir",
};

const UserDetails = ({ open, onClose, loading, userDetails }) => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [identityCardImgA, setIdentityCardImgA] = useState(null);
  const [identityCardImgB, setIdentityCardImgB] = useState(null);
  const [driverLicence, setDriverLicence] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (userDetails?.id) {
        const { data } = await getProfilePicture(userDetails.id);
        setLoadingFiles(true);
        try {
          await Promise.all([
            getDocumentFile("IDENTITY_DOCUMENT_A"),
            getDocumentFile("IDENTITY_DOCUMENT_B"),
            getDocumentFile("DRIVER_LICENSE"),
          ]);
        } catch (error) {
          console.error("Error fetching document files", error);
        } finally {
          setLoadingFiles(false);
          setProfileImage(data);
        }
      }
    }

    fetchData();

    return () => {
      setProfileImage(null);
      setIdentityCardImgA(null);
      setIdentityCardImgB(null);
      setDriverLicence(null);
    };
  }, [userDetails?.id]);

  const getDocumentFile = async (documentType) => {
    const { status, data } = await getProfileIdentityCardImage(
      userDetails.id,
      documentType
    );

    if (status === 200) {
      switch (documentType) {
        case "IDENTITY_DOCUMENT_A":
          setIdentityCardImgA({
            file: data,
            label: IDENTITY_DOCUMENT_LABELS[documentType],
          });
          break;
        case "IDENTITY_DOCUMENT_B":
          setIdentityCardImgB({
            file: data,
            label: IDENTITY_DOCUMENT_LABELS[documentType],
          });
          break;
        case "DRIVER_LICENSE":
          setDriverLicence({
            file: data,
            label: IDENTITY_DOCUMENT_LABELS[documentType],
          });
          break;
        default:
          console.warn(`Unhandled document type: ${documentType}`);
      }
    }
  };

  const onCloseModal = () => {
    onClose();
  };
  return (
    <Drawer
      closable={false}
      title={
        <div>
          <Row justify="space-between" align="middle">
            <Col>Detalles</Col>
            <Col>
              <Button
                onClick={onClose}
                style={{
                  border: "none",
                  padding: "5px 8px",
                  color: "#FF3200",
                  borderRadius: "6px",
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                }}
              >
                <CloseOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      }
      placement="right"
      onClose={onCloseModal}
      visible={open}
      width={biggerThan900 ? 480 : "100%"}
    >
      <Spin spinning={loading}>
        <ProfileUserCard
          name={`${userDetails?.firstName || ""} ${
            userDetails?.lastName || ""
          }`}
          rut={userDetails?.rut}
          urlImage={
            profileImage ||
            " https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
          }
        />
      </Spin>

      <Divider />
      <div>
        <div style={{ paddingBottom: "10px" }}>
          <Text strong>Correo electrónico </Text>
          <Text>{userDetails?.email || ""}</Text>
        </div>
        <div>
          <Text strong>Teléfono </Text>
          <Text>{`+${userDetails?.phonePrefix || ""} ${
            userDetails?.phoneNumber || ""
          }`}</Text>
        </div>
      </div>

      <Spin spinning={loadingFiles}>
        {driverLicence && (
          <>
            <Divider />
            <Text strong>Licencia</Text>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <FileLink {...driverLicence} />
              </Col>
            </Row>
          </>
        )}

        {(identityCardImgA || identityCardImgB) && (
          <>
            <Divider />
            <Text strong>Carnet de identidad</Text>
            <Row gutter={[16, 16]}>
              {identityCardImgA && (
                <Col span={24}>
                  <FileLink {...identityCardImgA} />
                </Col>
              )}
              {identityCardImgB && (
                <Col span={24}>
                  <FileLink {...identityCardImgB} />
                </Col>
              )}
            </Row>
          </>
        )}
      </Spin>

      <Divider />
      <Text strong>Perfiles y empresas</Text>
      <Row>
        {userDetails?.accesses.map((access) => {
          return (
            <>
              <Tag
                style={{
                  margin: 5,
                  backgroundColor: "#F0FDFF",
                  color: "#16BDE3",
                  border: "1px solid #16BDE3",
                  borderRadius: "5px",
                }}
              >
                {access.role.code}
              </Tag>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ul
                  style={{
                    listStyleType: "square",
                    backgroundColor: "#FAFAFA",
                    paddingLeft: "40px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderRadius: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <li>
                    <Text>{access.customer.name}</Text>
                  </li>
                </ul>
              </div>
            </>
          );
        })}
        <Row align="middle" justify="center" style={{ marginTop: "50px" }}>
          <CustomButton onClick={onCloseModal}>Cerrar</CustomButton>
        </Row>
      </Row>
    </Drawer>
  );
};

export default UserDetails;
