import { EyeOutlined } from "@ant-design/icons";

const FileLink = ({ file, label }) => {
  const fileUrl = URL.createObjectURL(
    new Blob([Buffer.from(file.buffer, "base64")], { type: file.contentType })
  );

  return (
    <div style={{ width: "100%" }}>
      <div>{label}:</div>
      <div style={{ width: "100%" }}>
        <a
          style={{
            padding: 5,
            border: "1px solid #FF3200",
            borderRadius: "8px",
            width: "100%",
            borderStyle: "dashed",
            display: "inline-flex",
            gap: 10,
            justifyContent: "center",
          }}
          href={fileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <EyeOutlined />
          {file.filename}
        </a>
      </div>
    </div>
  );
};

export default FileLink;
