import { EyeOutlined } from "@ant-design/icons";

const ViewDocumentIcon = ({ handleView }) => {
  return (
    <EyeOutlined
      onClick={handleView}
      style={{
        fontSize: "20px",
        color: "#FF5F00",
        cursor: "pointer",
      }}
    />
  );
};

export default ViewDocumentIcon;
